import './src/styles/fonts/fonts.css';

function intersectionObserverSupported() {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
        get() {
          return this.intersectionRatio > 0;
        },
      });
    }
    return true;
  }

  return false;
}

function smoothScrollSupported() {
  return 'scrollBehavior' in document.documentElement.style;
}

function loadScript(src) {
  const js = document.createElement('script');
  js.src = src;
  document.head.appendChild(js);
}

export const onClientEntry = () => {
  const polyfills = [];

  if (!intersectionObserverSupported()) {
    polyfills.push('IntersectionObserver');
  }

  if (!smoothScrollSupported()) {
    polyfills.push('smoothscroll');
  }

  if (polyfills.length > 0) {
    loadScript(`https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join('%2C')}`);
  }
};

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   // transition duration from `layout.js` * 1000 to get time in ms
//   // * 2 for exit + enter animation
//   const TRANSITION_DELAY = 0.3 * 1000;
//   // if it's a "normal" route
//   if (location.action === 'PUSH') {
//     window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
//   }
//   // if we used the browser's forwards or back button
//   else {
//     const savedPosition = getSavedScrollPosition(location) || [0, 0];
//     window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY);
//   }
//   return false;
// };

// export const shouldUpdateScroll = () => false;
