import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --space-quarter: 0.3125rem; // 5
    --space-half: 0.625rem; // 10
    --space: 1.25rem; // 20
    --space-plus-half: 1.875rem; // 30
    --space-double: 2.5rem; // 40
    --space-triple: 3.75rem; // 60
    
    --layout-max: 1440px;
    --gutter: 1.25rem; // 20
    --grid-gap: 1.25rem; // 20
    /* --section-spacing: 1.875rem; // 30 */

    --spacing-small: 3.5rem; // 56px;

    ${mq.medium} {
      --gutter: 2.5rem; // 40
      /* --grid-gap: 2.5rem; // 40 */
      /* --section-spacing: 6.5625rem; // 105 */

      --spacing-small: 3.5rem; // 56px;
    }

    --navigation-height: 4.12rem;

    ${mq.medium} {
      --navigation-height: 2.8125rem;
    }

    --font-family: 'Lapidar', sans-serif;
    
    --font-default: 1rem / 1.37 var(--font-family);
    --font-small: 0.75rem / 1.33 var(--font-family); // 12/16
    --font-navigation: 1.5rem / 1.33 var(--font-family);
    --font-footer: 1.5rem / 1 var(--font-family); //24/24
    --font-page-title: 2.25rem / 1 var(--font-family);
    --font-project-card-title: 1.5rem / 1.16 var(--font-family);
    --font-project-card: var(--font-small);
    

    --font-h2: 1.5rem/ 1.33 var(--font-family); // 24/32
    --font-h3: 1.5rem/ 1.33 var(--font-family); // 24/32
    

    ${mq.medium} {
      --font-default: 1.125rem / 1.44 var(--font-family);
      --font-navigation: 1.125rem / 1.2 var(--font-family);

      --font-h2: 4rem/ 1.2 var(--font-family); // 64/77
      --font-h3: 2.25rem/ 1.2 var(--font-family); // 36/43
      
      --font-page-title: 7.5rem / 1 var(--font-family);

      --font-project-card: 1.125rem / 1.222 var(--font-family); // 18/22
    }

    --color-white: #fff;
    --color-black: #000;

    --color-text: var(--color-black);

    --color-grey: rgba(0,0,0,0.4);

    --motion: 200ms;
    --motion-half: 100ms;
    --motion-double: 400ms;
    --ease: 
  }
`;

export default Vars;
