import { createGlobalStyle, css } from 'styled-components';
import mq from '@mq';

import { truncate, layoutWrapper } from '@mixins';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 1.2;
    height: 100%;
    width: 100%;
    background: var(--color-white);
    color: var(--color-text);
    
    -webkit-font-smoothing: antialiased;
  }

  main {
    ${layoutWrapper};
    font: var(--font-default);
    
  }

  section {
    
  }

  h1 {

  }

  h2 {
    font: var(--font-h2);
  }

  h3 {
    font: var(--font-h3);
  }

  h4 {

  }

  small {

  }

  a {
    &:hover {

    }
  }

  img {
    max-width: 100%;
    display: block;
    // prevents right click on image
    pointer-events: none;
  }

  figure {
    // prevents right click on image
    pointer-events: none;
    
    .gatsby-image-wrapper-constrained {
      display: block;
    }

    figcaption {
      margin-top: 0.5rem;
      text-align: right;
      font: var(--font-small);
    }
  }

  /* figure {
    display: grid;
    align-content: end;
    row-gap: var(--space-quarter);
  }

  figcaption {
    ${truncate};
    font: var(--font-small);
    text-align: center;
  } */
`;

export default Base;
