// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-beteiligte-js": () => import("./../../../src/pages/beteiligte.js" /* webpackChunkName: "component---src-pages-beteiligte-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-entwuerfe-js": () => import("./../../../src/pages/entwuerfe.js" /* webpackChunkName: "component---src-pages-entwuerfe-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-texte-js": () => import("./../../../src/pages/texte.js" /* webpackChunkName: "component---src-pages-texte-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/Location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-text-article-js": () => import("./../../../src/templates/TextArticle.js" /* webpackChunkName: "component---src-templates-text-article-js" */)
}

