const breakpoints = {
  medium: 768 / 16,
  large: 1040 / 16,
  huge: 1280 / 16,
  max: 1456 / 16,
};

const mq = {
  medium: `@media (min-width: ${breakpoints.medium}em)`,
  large: `@media (min-width: ${breakpoints.large}em)`,
  huge: `@media (min-width: ${breakpoints.huge}em)`,
  max: `@media (min-width: ${breakpoints.max}em)`,
};

const mqdown = {
  medium: `@media (max-width: ${breakpoints.medium - 0.01}em)`,
  large: `@media (max-width: ${breakpoints.large - 0.01}em)`,
  huge: `@media (max-width: ${breakpoints.huge - 0.01}em)`,
  max: `@media (max-width: ${breakpoints.max - 0.01}em)`,
};

export { mqdown };
export default mq;
