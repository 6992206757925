/* eslint-disable no-plusplus */
function chunkify(originalArray, parts) {
  const result = [];
  const array = [...originalArray];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

export default chunkify;
