import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import mq, { mqdown } from '@mq';
import { isMobile } from '@utils';
import Image from '@core/Image';
import { linkHover } from '@mixins';

import BundesregierungSvg from '@icons/bundesregierung.svg';
import BauhausUniversitySvg from '@icons/bauhaus-university.svg';

const partnersQuery = graphql`
  query partnersQuery {
    contentfulPartnersPage {
      partners {
        id
        title
        link
        image {
          gatsbyImageData(
            width: 120
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            quality: 80
          )
        }
      }
    }
  }
`;

// Todo move site ttitle to prop
const Footer = () => {
  const {
    contentfulPartnersPage: { partners },
  } = useStaticQuery(partnersQuery);

  return (
    <Container>
      <FooterSection>
        <Links>
          <li>
            <a
              href="mailto:info@synagogen-projekt.de"
              title="Das Synagogen-Projekt email"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kontakt
            </a>
          </li>
          <li>
            <GatsbyLink
              to="/impressum"
              state={{
                modal: !isMobile,
              }}
            >
              Impressum
            </GatsbyLink>
          </li>
          <li>
            <GatsbyLink
              to="/datenschutz"
              state={{
                modal: !isMobile,
              }}
            >
              Datenschutz
            </GatsbyLink>
          </li>
        </Links>
        <MainPartners>
          <div>Gefördert von:</div>
          <a
            href="https://www.uni-weimar.de/de/universitaet/forschung-und-kunst/foerdermoeglichkeiten/forschungsfoerderung/uni-interne-foerdermoeglichkeiten/kreativfonds/"
            title="Bauhaus-Universität Weimar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../images/bauhaus-university.jpg"
              alt="Bauhaus University"
              width={144}
            />
          </a>
          <a
            href="https://www.bundesregierung.de/breg-de/bundesregierung/staatsministerin-fuer-kultur-und-medien"
            title="Beauftragter der Bundesregierung für Kultur und Medien"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BundesregierungSvg />
          </a>
        </MainPartners>
      </FooterSection>
      <DarkSection>
        <Title>
          {'das synagogen projekt'.split(' ').map((word, index) => (
            <div key={index}>{word}</div>
          ))}
        </Title>
        <Partners>
          {partners.map(({ link, id, title, image }) => {
            if (link) {
              return (
                <ExternalLink
                  key={id}
                  href={link}
                  title={title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo image={image} />
                </ExternalLink>
              );
            }

            return <Logo key={id} image={image} />;
          })}
        </Partners>
      </DarkSection>
    </Container>
  );
};

const Container = styled.footer`
  margin-top: 7.5rem;

  ${mq.medium} {
    margin-top: 15rem;
  }
`;

const FooterSection = styled.div`
  display: grid;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: var(--space-double);
  padding-bottom: var(--space-double);
  row-gap: var(--space-double);

  ${mq.medium} {
    grid-auto-flow: column;
  }
`;

const DarkSection = styled(FooterSection)`
  background-color: var(--color-black);
  color: var(--color-white);

  ${mq.medium} {
    grid-template-columns: 1fr auto;
  }
`;

const Title = styled.h1`
  font: var(--font-footer);
  text-transform: uppercase;
`;

const Partners = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-auto-flow: column;
  justify-content: space-between;

  ${mq.medium} {
    column-gap: 2rem;
  }
`;

const MainPartners = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 2rem;
  font: var(--font-small);

  ${mqdown.medium} {
    row-gap: 1rem;
    column-gap: 1rem;
    order: -1;
    justify-content: space-between;

    > div {
      grid-row: 1;
    }

    a {
      grid-row: 2;
    }
  }

  ${mq.medium} {
    align-self: end;
    justify-self: end;
  }

  svg,
  img {
    width: 9rem;
    height: auto;
  }
`;

const Links = styled.ul`
  display: flex;
  justify-content: space-between;

  ${mq.medium} {
    display: grid;
    row-gap: 1rem;
    grid-auto-flow: column;
    column-gap: var(--space-double);
    justify-content: start;
  }

  li {
    text-transform: uppercase;
    font: var(--font-small);
    ${linkHover};
  }
`;

const Logo = styled(Image)`
  filter: invert(1);
`;

const ExternalLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Footer;
