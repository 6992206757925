import { css } from 'styled-components';
import { isBrowser } from '@utils';

function scrollToTop() {
  if (isBrowser) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

function scrollToElement(element) {
  let targetElement;

  if (isDomElement(element)) {
    targetElement = element;
  } else if (isString(element)) {
    targetElement = document.querySelector(element);
  } else {
    return;
  }

  if (!targetElement) {
    return;
  }

  targetElement.scrollIntoView({
    behavior: 'smooth',
  });
}

function getScrollMargin(margin) {
  return css`
    padding-top: ${margin};
    margin-top: calc(-1 * ${margin});
  `;
}

function isDomElement(value) {
  return value instanceof Element || value instanceof HTMLDocument;
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

export default {
  toTop: scrollToTop,
  toElement: scrollToElement,
  setMargin: getScrollMargin,
};
