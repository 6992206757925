import { css } from 'styled-components';
// import mq from '@mq';

import mq from '@mq';

const grid = css`
  display: grid;

  ${mq.medium} {
    column-gap: var(--grid-gap);
    grid-template-columns: repeat(8, 1fr);
  }
`;

const layoutWrapper = css`
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  max-width: var(--layout-max);
  margin: 0 auto;
`;

const hyphenate = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const customSpacing = css`
  --section-spacing-multiplier: ${props => props.spacing};
`;

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const linkHover = css`
  ${mq.medium} {
    transition: color var(--motion-half) ease-in-out;

    path,
    rect {
      transition: fill var(--motion-half) ease-in-out, stroke var(--motion-half) ease-in-out;
    }

    &:hover {
      --content-color: var(--color-grey);
      color: var(--content-color);
    }
  }
`;

const imageHover = css`
  ${mq.medium} {
    transition: filter var(--motion);

    &:hover {
      filter: grayscale(1);
    }
  }
`;

const richText = css`
  p {
    white-space: pre-line;
  }

  p + p {
    margin-top: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  a {
    text-decoration: underline;
    ${linkHover};
  }

  ul {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    li {
      display: flex;

      &:before {
        content: '•';
        display: block;
        margin: 0 1rem;
        height: 100%;
      }
    }
  }
`;

export {
  customSpacing,
  richText,
  grid,
  truncate,
  layoutWrapper,
  center,
  hyphenate,
  linkHover,
  imageHover,
};
