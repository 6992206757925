import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = ({ image, caption = true, className }) => {
  if (!image) {
    return null;
  }

  return (
    <figure className={className}>
      <GatsbyImage
        image={getImage(image.gatsbyImageData)}
        alt={image.description || image.altText || ''}
      />
      {caption && image.description && <figcaption>{image.description}</figcaption>}
    </figure>
  );
};

export default Image;
