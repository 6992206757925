import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import GlobalStyles from '@styles/GlobalStyles';

import Footer from '@core/Footer';
import Seo from '@core/Seo';

const Layout = ({ children, location }) => (
  <>
    <GlobalStyles />
    <Seo />
    {children}
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
